<template>
  <BaseCard class="searchable-table-card" padding="0">
    <div class="searchable-table-card__header">
      <div class="title">{{ headerLabel }}</div>
      <Button
        v-if="headerBtn"
        style="padding: 5px 15px"
        :variant="headerBtn.variant"
        :label="headerBtn.label"
        @click="headerBtn.action"
      />
    </div>

    <div class="searchable-table-card__body">
      <FormulateInput
        type="search"
        name="search"
        class="custom-search-left"
        :placeholder="searchPlaceholder"
        v-model="search"
      />
      <div class="tabel-list">
        <div
          class="tabel-list__item"
          v-for="(item, idx) in searchQuery"
          :key="idx"
          :data="item"
        >
          <div class="wrapper">
            <div class="label">{{ item.label }}</div>

            <router-link class="link" :to="item.link ? item.link : ''">
              <font-awesome-icon icon="custom-external-link" />
            </router-link>

            <div class="status" v-if="item.status !== undefined">
              {{ item.status }}
            </div>

            <div v-if="sortable" class="sorting">
              <font-awesome-icon
                class="chevron-up"
                icon="chevron-up"
                @click="sortUpMethod(idx)"
                v-show="idx > 0"
              />
              <font-awesome-icon
                icon="chevron-down"
                class="chevron-down"
                @click="sortDownMethod(idx)"
                v-show="searchQuery.length - 1 > idx"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import Button from "@/components/Buttons/Button.vue";
import BaseCard from "@/components/Cards/BaseCard";

import { sortUp } from "@/services/sorting";
import { sortDown } from "@/services/sorting";

export default {
  name: "SearchableTableCard",
  components: {
    Button,
    BaseCard,
  },
  props: {
    searchPlaceholder: {
      type: String,
      default: "",
    },
    headerLabel: {
      type: String,
      default: "",
    },
    headerBtn: {
      type: Object,
      default: () => {},
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      collapsed: true,
    };
  },
  computed: {
    searchQuery() {
      return this.list.filter((item) => {
        return item.label.match(this.search);
      });
    },
  },
  methods: {
    sortDownMethod(index) {
      sortDown(this.searchQuery, index, this.$set);
    },
    sortUpMethod(index) {
      sortUp(this.searchQuery, index, this.$set);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.searchable-table-card {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light_gray;
    padding: 25px;

    .title {
      font-family: "Oswald", sans-serif;
    }
  }

  &__body {
    .custom-search-left {
      margin: 25px;
    }

    .tabel-list {
      max-width: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: $scrollbar_height;
      }

      &::-webkit-scrollbar-track {
        background: $light_gray;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $navy;
      }

      &__item {
        min-width: 250px;
        padding-right: 25px;

        &:not(:last-child) {
          .wrapper {
            border-bottom: 1px solid $light_gray;
          }
        }

        .wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          padding-left: 25px;
        }

        .label {
          flex: 0 0 160px;
          color: $dark_text;
          font-size: 0.875rem;
        }

        .link {
          display: flex;
          justify-content: center;
          flex: 0 0 20px;
          color: $light_text;
        }

        .status {
          flex: 0 0 50px;
          color: $light_text;
          font-size: 0.875rem;
          text-align: center;
        }

        .sorting {
          flex: 0 0 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: $light_text;
        }
      }
    }
  }
}
</style>
