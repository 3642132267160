<template>
  <div class="sub-content content-padding">
    <TableOverview
      kind="module"
      :page-title="labels.submodules"
      :searchable="true"
      :settings="settings"
      :footer-buttons="footerButtons"
      :get-page="getSubModulesMt"
      :sort-up-fn="sortUp"
      :sort-down-fn="sortDown"
    >
      <template v-slot:optional-content>
        <div class="row mt-4">
          <div class="col-12 col-lg-6 mb-4 mb-lg-0">
            <SearchableTableCard
              :list="definitionsData.definitionList"
              :search-placeholder="labels.write_here_to_search"
              :header-btn="definitionsData.headerBtn"
              :header-label="labels.definitions"
              :searchable="true"
            />
          </div>
          <div class="col-12 col-lg-6">
            <SearchableTableCard
              :list="cardsData.cardList"
              :search-placeholder="labels.write_here_to_search"
              :header-btn="cardsData.headerBtn"
              :header-label="labels.cards"
              :searchable="true"
              :sortable="true"
            />
          </div>
        </div>
      </template>
    </TableOverview>
  </div>
</template>

<script>
import TableOverview from "@/views/Dashboard/Common/TableOverview/";
import SearchableTableCard from "@/components/Table/SearchableTableCard.vue";
const colors = require("/colors.config.json");
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SubContent",
  data() {
    return {
      definitionsData: {
        definitionList: [],
      },
      cardsData: {
        cardList: [],
      },
    };
  },
  components: {
    TableOverview,
    SearchableTableCard,
  },
  methods: {
    ...mapActions("_module", {
      getModules: "getList",
      sortSubModule: "sortSubModule",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("card", {
      getCards: "getList",
    }),
    getCardsMt(page) {
      let data = { page: this.page };
      if (page) data.page = page;

      this.getCards(data).then((res) => {
        if (res.success) {
          let resultList = res.data.data;
          if (resultList.length >= 1) {
            resultList.forEach((item) => {
              if (item.kind === 0)
                this.cardsData.cardList.push({
                  _id: item._id,
                  label: item.title,
                  actions: [
                    {
                      type: "icon",
                      icon: "custom-external-link",
                      action: () => {},
                    },
                  ],
                });

              if (item.kind === 1)
                this.definitionsData.definitionList.push({
                  _id: item._id,
                  label: item.title,
                  actions: [
                    {
                      type: "icon",
                      icon: "custom-external-link",
                      action: () => {},
                    },
                  ],
                });
            });
          }
        } else if (res.error) {
          this.addNotification({
            variant: "danger",
            msg: res.error,
            labels: this.labels,
          });
        }
      });
    },
    getSubModulesMt(page) {
      let data = {
        page,
        parent: this.$route.params.id,
      };

      this.getModules(data)
        .then((res) => {
          if (res.success) {
            let subModules = [];
            res.data.data.forEach((item) => {
              subModules.push({
                _id: item._id,
                label: item.title,
                actions: [
                  {
                    type: "icon",
                    icon: "custom-external-link",
                    action: () => {},
                  },
                ],
              });
            });

            this.tableList = subModules;
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    sortUp(obj, idx) {
      const id = obj.tableList[idx]._id;
      console.log("Sort up submodule with id:", id);

      this.sortSubModule({
        id: id,
        direction: "left",
      })
        .then((res) => {
          if (res.success) {
            let temp = obj.tableList[idx];
            obj.tableList[idx] = obj.tableList[idx - 1];
            obj.tableList[idx - 1] = temp;
            obj.updateModulesList(obj.tableList);
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    sortDown(obj, idx) {
      const id = obj.tableList[idx]._id;
      console.log("Sort down submodule with id:", id);

      this.sortSubModule({
        id: id,
        direction: "right",
      })
        .then((res) => {
          if (res.success) {
            let temp = obj.tableList[idx];
            obj.tableList[idx] = obj.tableList[idx + 1];
            obj.tableList[idx + 1] = temp;
            obj.updateModulesList(obj.tableList);
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    footerButtons() {
      return [];
    },
    settings() {
      return {
        header: [
          {
            headerLabel: "Name",
            headerColor: `${colors.gray_text}`,
            key: "title",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 0 auto",
            whiteSpace: "nowrap",
          },
        ],
        sortable: true,
        sortStyles: { flex: "0 0 50px", color: `${colors.gray_text}` },
        minTableWidth: "300px",
        actionsWidth: "0 0 100px",
        actionsHeaderLabel: "",
        actions: [
          {
            label: "Open",
            variant: "btn-purple",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (i) => {
              this.$router.push({
                name: "module-editor",
                params: { id: i._id },
              });
              this.$router.go(0);
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.getCardsMt(1);
    this.getSubModulesMt();
  },
};
</script>

<style lang="scss" scoped></style>
